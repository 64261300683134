$color-primary: #009fd3;
$color-secondary: #2c3941;
$mat-white-color-rgb: #fff;
$mat-dark-grey-color: #757575;
$mat-secondary-color: rgba(#000, 0.54);

$mobile: 400px;
$tablet: 800px;
$desktop: 1200px;
$ql-space: 8px;
html:root, *:root, :root {
    // primary color
    --mdc-theme-primary-50: #E0F3FA;
    --mdc-theme-primary-100: #B3E2F2;
    --mdc-theme-primary-200: #80CFE9;
    --mdc-theme-primary-300: #4DBCE0;
    --mdc-theme-primary-400: #26ADDA;
    --mdc-theme-primary-500: #{$color-primary};
    --mdc-theme-primary-600: #0097CE;
    --mdc-theme-primary-700: #008DC8;
    --mdc-theme-primary-800: #0083C2;
    --mdc-theme-primary-900: #0072B7;
    --mdc-theme-primary-A100: #E0F2FF;
    --mdc-theme-primary-A200: #ADDCFF;
    --mdc-theme-primary-A400: #7AC6FF;
    --mdc-theme-primary-A700: #61BBFF;
    --mdc-theme-primary-50-ct: #000000;
    --mdc-theme-primary-100-ct: #000000;
    --mdc-theme-primary-200-ct: #000000;
    --mdc-theme-primary-300-ct: #000000;
    --mdc-theme-primary-400-ct: #ffffff;
    --mdc-theme-primary-500-ct: #ffffff;
    --mdc-theme-primary-600-ct: #ffffff;
    --mdc-theme-primary-700-ct: #ffffff;
    --mdc-theme-primary-800-ct: #ffffff;
    --mdc-theme-primary-900-ct: #ffffff;
    --mdc-theme-primary-A100-ct: #000000;
    --mdc-theme-primary-A200-ct: #000000;
    --mdc-theme-primary-A400-ct: #000000;
    --mdc-theme-primary-A700-ct: #000000;
    --mdc-theme-primary: #{$color-primary};
    --mdc-theme-primary-rgb: #{toRGB($color-primary)};

    // secondary color
    --mdc-theme-secondary-50: #E6E7E8;
    --mdc-theme-secondary-100: #C0C4C6;
    --mdc-theme-secondary-200: #969CA0;
    --mdc-theme-secondary-300: #6B747A;
    --mdc-theme-secondary-400: #4C575E;
    --mdc-theme-secondary-500: #{$color-secondary};
    --mdc-theme-secondary-600: #27333B;
    --mdc-theme-secondary-700: #212C32;
    --mdc-theme-secondary-800: #1B242A;
    --mdc-theme-secondary-900: #10171C;
    --mdc-theme-secondary-A100: #60B8FF;
    --mdc-theme-secondary-A200: #2DA2FF;
    --mdc-theme-secondary-A400: #008AF9;
    --mdc-theme-secondary-A700: #007CE0;
    --mdc-theme-secondary-50-ct: #000000;
    --mdc-theme-secondary-100-ct: #000000;
    --mdc-theme-secondary-200-ct: #000000;
    --mdc-theme-secondary-300-ct: #ffffff;
    --mdc-theme-secondary-400-ct: #ffffff;
    --mdc-theme-secondary-500-ct: #ffffff;
    --mdc-theme-secondary-600-ct: #ffffff;
    --mdc-theme-secondary-700-ct: #ffffff;
    --mdc-theme-secondary-800-ct: #ffffff;
    --mdc-theme-secondary-900-ct: #ffffff;
    --mdc-theme-secondary-A100-ct: #000000;
    --mdc-theme-secondary-A200-ct: #000000;
    --mdc-theme-secondary-A400-ct: #ffffff;
    --mdc-theme-secondary-A700-ct: #ffffff;
    --mdc-theme-secondary: #{$color-secondary};
    --mdc-theme-secondary-rgb: #{toRGB($color-secondary)};

    // error color
    --mdc-theme-error-50: #FCE7E7;
    --mdc-theme-error-100: #F7C4C2;
    --mdc-theme-error-200: #F29C9A;
    --mdc-theme-error-300: #ED7472;
    --mdc-theme-error-400: #E95753;
    --mdc-theme-error-500: #E53935;
    --mdc-theme-error-600: #E23330;
    --mdc-theme-error-700: #DE2C28;
    --mdc-theme-error-800: #DA2422;
    --mdc-theme-error-900: #D31716;
    --mdc-theme-error-A100: #FFFFFF;
    --mdc-theme-error-A200: #FFD1D1;
    --mdc-theme-error-A400: #FF9F9E;
    --mdc-theme-error-A700: #FF8585;
    --mdc-theme-error-50-ct: #000000;
    --mdc-theme-error-100-ct: #000000;
    --mdc-theme-error-200-ct: #000000;
    --mdc-theme-error-300-ct: #000000;
    --mdc-theme-error-400-ct: #000000;
    --mdc-theme-error-500-ct: #ffffff;
    --mdc-theme-error-600-ct: #ffffff;
    --mdc-theme-error-700-ct: #ffffff;
    --mdc-theme-error-800-ct: #ffffff;
    --mdc-theme-error-900-ct: #ffffff;
    --mdc-theme-error-A100-ct: #000000;
    --mdc-theme-error-A200-ct: #000000;
    --mdc-theme-error-A400-ct: #000000;
    --mdc-theme-error-A700-ct: #000000;
    --mdc-theme-error: #E53935;
    --mdc-theme-error-rgb: #{toRGB(#E53935)};

    // default variables
    --success-color: #008b00;
    --toolbar-height: 3.5rem;
    --time-transition: .25s;
    --fab-size: 3rem;
    --space-fab-size: calc(var(--fab-size) / 2);
    --mat-black-color: #000;
    --mat-black-color-rgb: 0, 0, 0;
    --mat-white-color: #fff;
    --mat-white-color-rgb: 255, 255, 255;
    --mat-grey-color: #e0e0e0;
    --mat-green-color: #00c853;
    --mat-grey-hover: rgba(var(--mat-black-color-rgb), 0.04);
    --mat-dark-grey-color: #757575;
    --mat-yellow-color: #ffc400;
    --mat-yellow-color-rgb: 255, 196, 0;
    --mat-orange-color: #f57f17;
    --mat-orange-color-rgb: 245, 127, 23;
    --mat-red-color: #E53935;
    --mat-red-color-rgb: 229, 57, 53;
    --mat-blue-color: #2196f3;
    --mat-blue-color-rgb: 33, 150, 243;
    --link-shadow: inset 0 -1px;
    --border: 1px solid var(--mat-grey-color);
    --default-space: 16px;
    --default-double-space: calc(var(--default-space) * 2);
    --default-triple-space: calc(var(--default-space) * 3);
    --default-fourth-space: calc(var(--default-space) * 4);
    --default-half-space: calc(var(--default-space) / 2);
    --default-half-of-half-space: calc(var(--default-space) / 4);
    --default-space-more-half-space: calc(var(--default-space) + var(--default-half-space));
    --default-font-size: 16px;
    --small-font-size: 14px;
    --mat-space-dialog: 24px;
    --mat-small-space-dialog: 16px;
    --mat-primary-color: rgba(var(--mat-black-color-rgb), 0.87);
    --mat-secondary-color: rgba(var(--mat-black-color-rgb), 0.54);
    --mat-field-color: rgba(var(--mat-black-color-rgb), 0.6);
    --mat-slider-wrapper-size: 4px;
    --mat-snack-bar-container-padding: 8px 8px 8px 20px;
    --mat-scroll-size: 8px;
    --mat-border-radius: 4px;
    --mat-input-margin: .25em;
    --mat-input-padding: .75em;
    --mat-input-placeholder-color: #7f7f7f;
    --mat-button-icon-size: 40px;
    --mat-error-message-width: 18px;
    --copyright-size: 45px;
    --copyright-padding: 16px;
    --big-padding: 1.5rem;
    --step-space: 24px;
    --icon-button-size: 40px;
    --menu-space: 8px;

    // material design variables
    --mdc-theme-background: var(--mat-white-color);
    --mdc-theme-surface: var(--mat-white-color);
    --mdc-theme-on-primary: var(--mat-white-color);
    --mdc-theme-on-secondary: var(--mat-white-color);
    --mdc-theme-on-surface: var(--mat-black-color);
    --mdc-theme-on-error: var(--mat-white-color);
    --mdc-theme-text-primary-on-background: var(--mat-primary-color);
    --mdc-theme-text-secondary-on-background: var(--mat-secondary-color);
    --mdc-theme-text-hint-on-background: rgba(var(--mat-black-color-rgb), 0.38);
    --mdc-theme-text-disabled-on-background: rgba(var(--mat-black-color-rgb), 0.38);
    --mdc-theme-text-icon-on-background: rgba(var(--mat-black-color-rgb), 0.38);
    --mdc-theme-text-primary-on-light: var(--mat-primary-color);
    --mdc-theme-text-secondary-on-light: var(--mat-secondary-color);
    --mdc-theme-text-hint-on-light: rgba(var(--mat-black-color-rgb), 0.38);
    --mdc-theme-text-disabled-on-light: rgba(var(--mat-black-color-rgb), 0.38);
    --mdc-theme-text-icon-on-light: rgba(var(--mat-black-color-rgb), 0.38);
    --mdc-theme-text-primary-on-dark: var(--mat-white-color);
    --mdc-theme-text-secondary-on-dark: rgba(var(--mat-white-color-rgb), 0.7);
    --mdc-theme-text-hint-on-dark: rgba(var(--mat-white-color-rgb), 0.5);
    --mdc-theme-text-disabled-on-dark: rgba(var(--mat-white-color-rgb), 0.5);
    --mdc-theme-text-icon-on-dark: rgba(var(--mat-white-color-rgb), 0.5);

    --ql-editor-default-font: revert !important;
}
