%texts{
    margin-bottom: 0;
    line-height: 1.25;
}

%mat-fields-space{
    padding-bottom: 1.34375em;
    margin: .25rem 0;
}

%no-selection{
    -webkit-touch-callout: default !important;
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;

    *::selection:not(input):not(textarea){
        color: inherit !important;
        background-color: transparent !important;
    }
}

%line-height-1{
    line-height: 1.25
}

%hide-text{
    z-index: -1;
    font-size: 1px;
    color: transparent;
    position: absolute;
    left: -9999px;
    top: -9999px;
}

%no-margin{
    margin: 0;
}

%navigation-drawer-width{
    max-width: 500px;
    width: 90%;
}

%ellipsis{
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%menu-title{
    margin: 0 var(--menu-space) var(--menu-space) var(--menu-space);
    color: var(--mat-secondary-color);
    font-size: .875rem;
    line-height: 2;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: flex-start;
}

%remove-padding{
    padding: 0 !important;
}

%remove-border{
    border: none !important;
}

%hide-hint-container{
    ::ng-deep .mat-form-field-wrapper{
        margin: 0 !important;
        padding: 0 !important;

        .mat-form-field-subscript-wrapper{
            display: none !important;
        }
    }
}
